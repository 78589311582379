import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { LoggedUser } from '../auth.interfaces';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanMatch {
  constructor(
    private authService: AuthService,
    private router: Router,
    private platform: Platform,
    private sharedService: SharedService
    ){}

  canMatch(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getLoggedUser();
  }

  async getLoggedUser() {
    let user: LoggedUser | null = null;
    await this.authService.recoverSession().then((loggedUser) => {
      user = loggedUser;
    });
    if (user) {
      let isMobile = this.sharedService.isMobile$.getValue();
      await this.platform.ready().then(() => {
        // if (this.platform.is('hybrid') || this.platform.is('ios')) {
        // }
        const width = this.platform.width();
        this.sharedService.screenWidth$.next(width);
        if (environment.MODE === "mobile" || width < 720) {
          isMobile = true;
          this.sharedService.isMobile$.next(true);
        }
      });
      if (isMobile) {
        return this.router.parseUrl(this.authService.mobile_main_page_url);
      }
      return this.router.parseUrl(this.authService.desktop_main_page_url);
    }
    return true;
  }
}
