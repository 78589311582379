import { GeneralStatus } from "src/app/shared/shared.interfaces";
import { GeneralApproval } from "src/app/shared/shared.interfaces";
import { Project } from "../management.interfaces";
import { TrainingCourse } from "../../training/training.interfaces";
import { TeamViewPreferences } from "../../tasks/tasks.interfaces";

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  shortName?: string;
  email: string;
  secondary_email?: string;
  phone?: string;
  job: Job;
  branches: Branch[];
  hr_branch?: Branch;
  branches_str?: string;
  hr_branch_name?: string;
  phone_company?: string;
  address?: string;
  emergency_contact?: string;
  next_of_kin?: string;
  lang: UserLanguage;
  access?: string[];
  special_access?: string[];
  role: UserRole;
  status: Status;
  fcm_token?: string;
  fcm_token_desktop?: string;
  profile?: UserProfile;
  meta?: UserMeta;
  teams?: Team[];
  token__?: string;
  _absent?: boolean;
  _rating?: number;
  _last_hiring?: string;
  _chart_main?: boolean;
}

export interface Job {
  id: number;
  name: string;
  description?: string;
  access?: string[];
  required_courses?: TrainingCourse[];
}

export interface Branch {
  id: number;
  name: string;
  country: Country;
  status: GeneralStatus;
  pm?: User;
  pm_email: string;
  marketing_name: string;
  system_name: string;
  legal_name: string;
  mailing_address: string;
  phone: string;
  multi_locations?: Branch[];
  reservations_email: string;
  casago_url: string;
  custom_url: string;
  leave_approvers?: User[];
  hr_employees?: User[];
  days_vacation?: GeneralStatus;
  days_off?: GeneralStatus;
  days_sick?: GeneralStatus;
  days_vacation_countable?: GeneralStatus;
  days_off_countable?: GeneralStatus;
  days_sick_countable?: GeneralStatus;
  _last_evaluation?: EvaluationCasa;
}

export interface BranchShared {
  bio: string;
  background: string;
  cover: string[];
  video: string[];
  status: GeneralApproval;
  branch?: Branch;
  author?: User;
  _pending?: boolean;
}

export interface UserProfile {
  id: number,
  hiring_date: string;
  birthday: string;
  antiquity?: string;
  boss_chart?: number;
  boss_id?: number;
  head_department: boolean;
  extra_info?: string;
  is_mentor?: boolean;
  mentor_id?: number;
  job_description?: string;
  photo?: string;
  work_periods: string;
  personality?: UserPersonality;
  days_vacation?: number;
  days_off?: number;
  days_sick?: number;
}

export interface UserMeta {
  tasks_team_view_preferences?: string;
  _tasks_team_view_preferences?: TeamViewPreferences;
}

export interface ProfileShared {
  id: number;
  first_name: string;
  last_name: string;
  years: number;
  favorite_book: string;
  hospitality_meaning: string;
  costco_memory: string;
  orange_fav: string;
  image: string;
  user?: User;
}

export interface CountryE {
  id: number;
  code: Country;
  name: string;
  pm?: User;
}

export interface EvaluationCasa {
  id: number;
  branch_id: number;
  branch?: string;
  author: string;
  data: any;
  grade: number;
  notes: string;
  photos: string;
  parsed_photos: string[];
  total: number;
  version: number;
  creation: string;
}

export enum Country {
  MEXICO = 'mx',
  USA = 'us'
}

export enum UserLanguage {
  SPANISH = 'es',
  ENGLISH = 'en'
}

export enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum WorkingPeriodType {
  ACTIVE = 'active',
  FINISHED = 'finished'
}

export interface WorkingPeriod {
  start: string,
  end: string
}

export enum UserRole {
  GUEST = 'guest',
  USER = 'user',
  ADMIN = 'admin',
  SUPER = 'super'
}

export interface Team {
  id: number;
  name: string;
  public: GeneralStatus;
  testing_tasks: GeneralStatus;
  members: User[];
  leads: User[];
  author: User;
  projects?: Project[];
}


export interface Notification {
  title: string;
  description?: string;
  author?: User;
}

export interface PushNotification {
  id: number;
  title: string;
  body: string;
  action: PushNotificationAction;
  url?: string;
  desktop_url?: string;
  creation: string;
  viewed?: string;
  user?: User;
}

export enum PushNotificationAction {
  REDIRECT = 'redirect',
  NONE = 'none'
}

export interface NotificationActionRequired {
  rel_id: number;
  title: string;
  description?: string;
  type: NotActionRequiredType;
}

export enum NotActionRequiredType {
  BRANCH_SHARED_APPROVAL = 'branch_shared_approval'
}

export enum UserPersonality {
  "Administrator" = "Administrator",
  "Architect" = "Architect",
  "Coordinator" = "Coordinator",
  "Craftsman" = "Craftsman",
  "Daredevil" = "Daredevil",
  "Debater" = "Debater",
  "Enterpriser" = "Enterpriser",
  "Facilitator" = "Facilitator",
  "Influencer" = "Influencer",
  "Operator" = "Operator",
  "Persuader" = "Persuader",
  "Philosopher" = "Philosopher",
  "Rainmaker" = "Rainmaker",
  "Scholar" = "Scholar",
  "Socializer" = "Socializer",
  "Specialist" = "Specialist",
  "Technical Expert" = "Technical Expert",
  "Traditionalist" = "Traditionalist",
  "Trailblazer" = "Trailblazer"
}

export const UserLanguageLabel = {
  es: 'Spanish',
  en: 'English'
}
