import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SharedService } from './shared/shared.service';
import { environment } from 'src/environments/environment';
import { TooltipComponent } from '@angular/material/tooltip';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private sharedService: SharedService
  ) {
    this.platform.ready().then(() => {
      // if (this.platform.is('hybrid') || this.platform.is('ios')) {
      // }
      const width = this.platform.width();
      this.sharedService.screenWidth$.next(width);
      if (environment.MODE === "mobile" || width < 720) {
        this.sharedService.isMobile$.next(true);
      }
    });

    Object.defineProperty(TooltipComponent.prototype, 'message', {
      set(v: any) {
        const el = document.querySelectorAll('.mdc-tooltip__surface');
        if (el && el.length > 0) {
          el[el.length - 1].innerHTML = v;
        }
      }
    });
  }
}
