import { GeneralStatus } from "src/app/shared/shared.interfaces";
import { Team, User } from "./users/users.interfaces";

export enum SubmenuState {
  EXPANDED = 'expanded',
  COLLAPSED = 'collapsed'
}

export interface Project {
  id: number;
  name: string;
  description: string;
  default_assignee?: number;
  backlog?: GeneralStatus;
  testing?: GeneralStatus;
  teams?: Team[];
  users?: User[];
  optional_fields?: string[];
  _tasks_no?: number;
  _allowed_users_ids?: number[];
}
