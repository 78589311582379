import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable,of,tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/shared.service';
import { Branch, BranchShared, Job, Notification, NotificationActionRequired, ProfileShared, PushNotification, Status, Team, User, UserProfile } from './users.interfaces';
import { CasaModule, GeneralStatus } from 'src/app/shared/shared.interfaces';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  editingMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  jobs$: BehaviorSubject<Job[]> = new BehaviorSubject<Job[]>([]);
  branches$: BehaviorSubject<Branch[]> = new BehaviorSubject<Branch[]>([]);
  teams$: BehaviorSubject<Team[]> = new BehaviorSubject<Team[]>([]);
  allTeams$: BehaviorSubject<Team[]> = new BehaviorSubject<Team[]>([]);
  teamsAdmin$: BehaviorSubject<Team[]> = new BehaviorSubject<Team[]>([]);
  pushNotifications$ = new BehaviorSubject<PushNotification[]>([]);
  badges$ = new BehaviorSubject<any>({
    [CasaModule.APP] : 0,
    [CasaModule.TASKS]: 0,
    [CasaModule.FORMS]: 0
  });

  constructor(
    private http: HttpClient,
    private sharedService: SharedService
  ) { }

  getUsers(status: GeneralStatus | null = GeneralStatus.ACTIVE): Observable<User[]> {
    return this.http
      .get<User[]>(`${environment.endpoint}/users?status=${status}`)
      .pipe(
        map((users: User[]) => {
          return users.map((user) => {
            return this._userTransform(user);
          });
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  getAdminUsers(): Observable<User[]> {
    return this.http
      .get<User[]>(`${environment.endpoint}/users/admin/users`)
      .pipe(
        map((users: User[]) => {
          return users.map((user) => {
            return this._userTransform(user);
          });
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  getAllUsers(status: GeneralStatus | null = GeneralStatus.ACTIVE): Observable<User[]> {
    return this.http
      .get<User[]>(`${environment.endpoint}/users/all/users?status=${status}`)
      .pipe(
        map((users: User[]) => {
          return users.map((user) => {
            return this._userTransform(user);
          });
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  getBranchUsers(branch_id: number, status?: Status): Observable<User[]> {
    const query = status ? `?status=${status}` : '';
    return this.http
      .get<User[]>(`${environment.endpoint}/users/hr-branch/${branch_id}/users${query}`)
      .pipe(
        map((users: User[]) => {
          return users.map((user) => {
            return this._userTransform(user);
          });
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  getUserHrBranch(user_id: number): Observable<Branch> {
    return this.http
      .get<Branch>(`${environment.endpoint}/users/${user_id}/hr-branch`)
      .pipe(
        catchError(this.sharedService.handleRequestError)
      );
  }

  getUser(user_id: number): Observable<User> {
    return this.http
      .get<User>(`${environment.endpoint}/users/${user_id}`)
      .pipe(
        map(user => {
          return this._userTransform(user);
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  // getUserOrgData(user_id: number): Observable<any> {
  //   return this.http
  //     .get<any>(`${environment.endpoint}/users/${user_id}/org-chart`)
  //     .pipe(
  //       catchError(this.sharedService.handleRequestError)
  //     );
  // }

  getUserProfile(user_id: number): Observable<User> {
    return this.http
      .get<User>(`${environment.endpoint}/users/${user_id}/profile`)
      .pipe(
        map(user => {
          if (user) {
            return this._userTransform(user);
          }
          return user;
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  getProfileShared(user_id: number): Observable<ProfileShared> {
    return this.http
      .get<ProfileShared>(`${environment.endpoint}/users/${user_id}/profile-shared`)
      .pipe(
        map(shared => {
          if (shared?.user) {
            shared.user = this._userTransform(shared.user);
          }
          return shared;
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  saveProfileShared(user_id: number, data: Partial<ProfileShared>): Observable<ProfileShared> {
    return this.http
    .patch<ProfileShared>(`${environment.endpoint}/users/${user_id}/profile-shared`, data)
    .pipe(
      catchError(this.sharedService.handleRequestError)
    )
  }

  deleteSharedProfileFile(user_id: number): Observable<ProfileShared> {
    return this.http
      .delete<ProfileShared>(`${environment.endpoint}/users/${user_id}/profile-shared/image`)
      .pipe(
        catchError(this.sharedService.handleRequestError)
      )
  }

  initUsers(all?: boolean): Observable<User[]> {
    const users = this.users$.getValue();
    if (!users || users.length == 0) {
      if (all) {
        return this.getAllUsers();
      }
      return this.getUsers();
    }
    return of(users);
  }

  addUser(data: any): Observable<User> {
    return this.http
      .post<User>(`${environment.endpoint}/auth/signup`, data, {
        headers: new HttpHeaders({
          'Content-Type' : 'application/json'
        })
      })
      .pipe(
        map(userResponse => {
          return this._userTransform(userResponse);
        }),
        tap(user => {
          const users = [...this.users$.value];
          if (users.length > 0) {
            users.push(user);
            this.users$.next(users);
          }
        }),
        catchError(this.sharedService.handleRequestError)
      )
  }

  editUser(user_id: number, data: any, updateUsers = true): Observable<User> {
    return this.http
      .patch<User>(`${environment.endpoint}/users/${user_id}`, data, {
        headers: new HttpHeaders({
          'Content-Type' : 'application/json'
        })
      })
      .pipe(
        map(userResponse => {
          return this._userTransform(userResponse);
        }),
        tap(userResponse => {
          if (updateUsers) {
            let users = [...this.users$.getValue()];
            const indx = users.findIndex((user) => user.id === userResponse.id);
            if (indx !== -1) {
              users[indx] = userResponse;
              this.users$.next(users);
            }
          }
        }),
        catchError(this.sharedService.handleRequestError)
      )
  }

  changeProfilePicture(user_id: number, url: string): Observable<UserProfile> {
    return this.http
      .patch<UserProfile>(`${environment.endpoint}/users/${user_id}/picture`, {
        url: url
      }, {
        headers: new HttpHeaders({
          'Content-Type' : 'application/json'
        })
      })
      .pipe(
        catchError(this.sharedService.handleRequestError)
      )
  }

  updateAccessByJob(job_id: number, access: string[]): Observable<any> {
    return this.http
      .patch<any>(`${environment.endpoint}/users/access/${job_id}`, {access}, {
        headers: new HttpHeaders({
          'Content-Type' : 'application/json'
        })
      })
      .pipe(
        catchError(this.sharedService.handleRequestError)
      )
  }

  getJobs(): Observable<Job[]> {
    return this.http
      .get<Job[]>(`${environment.endpoint}/jobs`)
      .pipe(
        catchError(this.sharedService.handleRequestError)
      );
  }

  getJob(job_id: number, rels?: boolean): Observable<Job> {
    const query = rels ? `?rels=1` : "";
    return this.http
      .get<Job>(`${environment.endpoint}/jobs/${job_id}${query}`)
      .pipe(
        catchError(this.sharedService.handleRequestError)
      );
  }

  addJob(data: Job): Observable<Job> {
    return this.http
      .post<Job>(`${environment.endpoint}/jobs/`, data)
      .pipe(
        tap(job => {
          let jobs = [...this.jobs$.value];
          if (jobs.length > 0) {
            jobs.push(job);
            jobs = this.sharedService.sortByProperty(jobs, 'name');
            this.jobs$.next(jobs);
          }
        }),
        catchError(this.sharedService.handleRequestError)
      )
  }

  editJob(job_id: number, data: Job): Observable<Job> {
    return this.http
      .patch<Job>(`${environment.endpoint}/jobs/${job_id}`, data)
      .pipe(
        tap(jobResponse => {
          let jobs = [...this.jobs$.value];
          const indx = jobs.findIndex((job) => job.id === jobResponse.id);
          if (indx !== -1) {
            jobs[indx] = jobResponse;
            jobs = this.sharedService.sortByProperty(jobs, 'name');
            this.jobs$.next(jobs);
          }
        }),
        catchError(this.sharedService.handleRequestError)
      )
  }

  deleteJob(job_id: number): Observable<Job> {
    return this.http
      .delete<Job>(`${environment.endpoint}/jobs/${job_id}`)
      .pipe(
        tap(jobResponse => {
          let jobs = [...this.jobs$.value].filter(job => job.id !== job_id);
          this.jobs$.next(jobs);
        }),
        catchError(this.sharedService.handleRequestError)
      )
  }

  initBranches(all?: boolean): Observable<Branch[]> {
    const branches = this.branches$.getValue();
    if (branches.length === 0) {
      if (all) {
        return this.getAllBranches();
      }
      return this.getBranches();
    }
    return of(branches);
  }

  getBranches(): Observable<Branch[]> {
    return this.http
      .get<Branch[]>(`${environment.endpoint}/branches`)
      .pipe(
        catchError(this.sharedService.handleRequestError)
      );
  }

  getAllBranches(): Observable<Branch[]> {
    return this.http
      .get<Branch[]>(`${environment.endpoint}/branches/all/branches`)
      .pipe(
        catchError(this.sharedService.handleRequestError)
      );
  }

  addBranch(data: Branch): Observable<Branch> {
    return this.http
      .post<Branch>(`${environment.endpoint}/branches/`, data)
      .pipe(
        tap(branch => {
          let branches = [...this.branches$.value];
          if (branches.length > 0) {
            branches.push(branch);
            branches = this.sharedService.sortByProperty(branches, 'name');
            this.branches$.next(branches);
          }
        }),
        catchError(this.sharedService.handleRequestError)
      )
  }

  editBranch(branch_id: number, data: Partial<Branch>): Observable<Branch> {
    return this.http
      .patch<Branch>(`${environment.endpoint}/branches/${branch_id}`, data)
      .pipe(
        tap(branchResponse => {
          let branches = [...this.branches$.value];
          const indx = branches.findIndex((branch) => branch.id === branchResponse.id);
          if (indx !== -1) {
            branches[indx] = branchResponse;
            branches = this.sharedService.sortByProperty(branches, 'name');
            this.branches$.next(branches);
          }
        }),
        catchError(this.sharedService.handleRequestError)
      )
  }

  deleteBranch(branch_id: number): Observable<Branch> {
    return this.http
      .delete<Branch>(`${environment.endpoint}/branches/${branch_id}`)
      .pipe(
        tap(branchResponse => {
          let branches = [...this.branches$.value].filter(branch => branch.id !== branch_id);
          this.branches$.next(branches);
        }),
        catchError(this.sharedService.handleRequestError)
      )
  }

  getSharedBranchData(branch_id: number): Observable<BranchShared> {
    return this.http
    .get<BranchShared>(`${environment.endpoint}/branches/${branch_id}/shared/data`)
    .pipe(
      catchError(this.sharedService.handleRequestError)
    )
  }

  saveSharedBranchData(branch_id: number, data: Partial<BranchShared>): Observable<BranchShared> {
    return this.http
    .patch<BranchShared>(`${environment.endpoint}/branches/${branch_id}/shared/data`, data)
    .pipe(
      catchError(this.sharedService.handleRequestError)
    )
  }

  approveSharedBranchData(branch_id: number, data: Partial<BranchShared>): Observable<BranchShared> {
    return this.http
    .patch<BranchShared>(`${environment.endpoint}/branches/${branch_id}/shared/data/approve`, data)
    .pipe(
      catchError(this.sharedService.handleRequestError)
    )
  }

  deleteSharedBranchFile(branch_id: number, url: string, type: "cover" | "video"): Observable<BranchShared> {
    const body = {type, url};
    return this.http
      .delete<BranchShared>(`${environment.endpoint}/branches/${branch_id}/shared/data`, {body})
      .pipe(
        catchError(this.sharedService.handleRequestError)
      )
  }

  getSharedBranchDataPending(branch_id: number): Observable<BranchShared> {
    return this.http
    .get<BranchShared>(`${environment.endpoint}/branches/${branch_id}/shared/data/pending`)
    .pipe(
      map(shared => {
        if (shared) {
          shared._pending = true;
        }
        return shared;
      }),
      catchError(this.sharedService.handleRequestError)
    )
  }

  getSharedBranchDataPendingById(shared_id: number): Observable<BranchShared> {
    return this.http
    .get<BranchShared>(`${environment.endpoint}/branches/shared/data/${shared_id}`)
    .pipe(
      map(shared => {
        if (shared) {
          shared._pending = true;
        }
        return shared;
      }),
      catchError(this.sharedService.handleRequestError)
    )
  }

  initTeams(include_public?: boolean): Observable<Team[]> {
    const teams = this.teams$.getValue();
    if (!teams || teams.length == 0) {
      return this.getTeams(include_public);
    }
    return of(teams);
  }

  initAdminTeams(): Observable<Team[]> {
    const teams = this.teamsAdmin$.getValue();
    if (!teams || teams.length == 0) {
      return this.getAdminTeams();
    }
    return of(teams);
  }

  initAllTeams(): Observable<Team[]> {
    const teams = this.allTeams$.getValue();
    if (!teams || teams.length == 0) {
      return this.getAllTeams();
    }
    return of(teams);
  }

  getTeams(include_public?: boolean): Observable<Team[]> {
    return this.http
      .get<Team[]>(`${environment.endpoint}/teams?public=${include_public ? 'active' : ''}`)
      .pipe(
        tap(teams => {
          if (!include_public) {
            this.teams$.next(teams);
          }
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  getAdminTeams(): Observable<Team[]> {
    return this.http
      .get<Team[]>(`${environment.endpoint}/teams/admin/managed`)
      .pipe(
        tap(teams => {
          this.teamsAdmin$.next(teams);
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  getAllTeams(): Observable<Team[]> {
    return this.http
      .get<Team[]>(`${environment.endpoint}/teams/admin/all`)
      .pipe(
        tap(teams => {
          this.allTeams$.next(teams);
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  addTeam(data: Team): Observable<Team> {
    return this.http
      .post<Team>(`${environment.endpoint}/teams`, data)
      .pipe(
        tap(team => {
          this._addLocalTeam(team, this.teamsAdmin$);
          this._addLocalTeam(team, this.allTeams$);
        }),
        catchError(this.sharedService.handleRequestError)
      )
  }

  private _addLocalTeam(team: Team, teams$: BehaviorSubject<Team[]>) {
    let teams = [...teams$.getValue()];
    if (teams.length > 0) {
      teams.push(team);
      teams = this.sharedService.sortByProperty(teams, 'name');
      teams$.next(teams);
    }
  }

  editTeam(team_id: number, data: Team): Observable<Team> {
    return this.http
      .patch<Team>(`${environment.endpoint}/teams/${team_id}`, data)
      .pipe(
        tap(teamResponse => {
          this._editLocalTeam(teamResponse, this.teams$);
          this._editLocalTeam(teamResponse, this.teamsAdmin$);
          this._editLocalTeam(teamResponse, this.allTeams$);
        }),
        catchError(this.sharedService.handleRequestError)
      )
  }

  private _editLocalTeam(teamResponse: Team, teams$: BehaviorSubject<Team[]>) {
    let teams = [...teams$.getValue()];
    const indx = teams.findIndex((team) => team.id === teamResponse.id);
    if (indx !== -1) {
      teams[indx] = teamResponse;
      teams$.next(teams);
    }
  }

  deleteTeam(team_id: number): Observable<Team> {
    return this.http
      .delete<Team>(`${environment.endpoint}/teams/${team_id}`)
      .pipe(
        tap(() => {
          this._deleteLocalTeam(team_id, this.teams$);
          this._deleteLocalTeam(team_id, this.teamsAdmin$);
          this._deleteLocalTeam(team_id, this.allTeams$);
        }),
        catchError(this.sharedService.handleRequestError)
      )
  }

  private _deleteLocalTeam(team_id: number, teams$: BehaviorSubject<Team[]>) {
    let teams = [...teams$.getValue()].filter(team => team.id !== team_id);
    teams$.next(teams);
  }

  getLastNotifications(): Observable<Notification[]> {
    return this.http
      .get<Notification[]>(`${environment.endpoint}/notifications/last`)
      .pipe(
        catchError(this.sharedService.handleRequestError)
      );
  }

  getActionRequiredNotifications(): Observable<NotificationActionRequired[]> {
    return this.http
      .get<NotificationActionRequired[]>(`${environment.endpoint}/notifications/action-required`)
      .pipe(
        catchError(this.sharedService.handleRequestError)
      );
  }

  getPendingPushNotifications(): Observable<PushNotification[]> {
    return this.http
      .get<PushNotification[]>(`${environment.endpoint}/notifications/pending`)
      .pipe(
        tap(notifications => {
          this.pushNotifications$.next(notifications);
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  getPendingBadgeTotals(): Observable<any> {
    return this.http
      .get<any>(`${environment.endpoint}/notifications/pending-badge`)
      .pipe(
        tap(badges => {
          this.badges$.next(badges);
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  markAsReadPushNotification(push_id: number): Observable<PushNotification[]> {
    return this.http
      .patch<PushNotification[]>(`${environment.endpoint}/notifications/pending/${push_id}`, {})
      .pipe(
        tap(notifications => {
          this.pushNotifications$.next(notifications);
        }),
        catchError(this.sharedService.handleRequestError)
      );
  }

  validateWorkedPeriods(user_id: number): Observable<any> {
    return this.http
      .get<any>(`${environment.endpoint}/users/${user_id}/validate/worked-periods`)
      .pipe(
        catchError(this.sharedService.handleRequestError)
      );
  }

  _silent_updateBandges() {
    this.getPendingBadgeTotals().subscribe();
  }

  _filterUsers(source: User[], value: User | string | null): User[] {
    if (value) {
      if (typeof(value) === 'string') {
        return source.filter(user => user.fullName?.toLowerCase().includes(value.toLowerCase()));
      }
      return source.filter(user => user.fullName?.toLowerCase().includes(value.fullName!.toLowerCase()));
    }
    return source;
  }

  _userTransform(user: User) {
    user.fullName = `${user.firstName} ${user.lastName}`;
    user.shortName = this._getShortName(user);
    if (user.branches) {
      user.branches_str = user.branches.map(b => b.name).join(', ');
    }
    if (user.hr_branch) {
      user.hr_branch_name = user.hr_branch.name;
    }
    return user;
  }

  _getShortName(user?: User): string{
    if (user) {
      return `${user.firstName.split(' ')[0]} ${user.lastName.split(' ')[0]}`;
    }
    return "";
  }
}
