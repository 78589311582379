import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController, NavController, ToastController } from '@ionic/angular';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, throwError } from 'rxjs';
import { SubmenuState } from '../desktop/management/management.interfaces';
import { RedirectionResponse } from './shared.interfaces';
import { User } from '../desktop/management/users/users.interfaces';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  isMobile$ = new BehaviorSubject<boolean>(false);
  screenWidth$ = new BehaviorSubject<number>(0);
  messageServiceRef?: MessageService;
  subMenuState$: BehaviorSubject<SubmenuState> = new BehaviorSubject<SubmenuState>(SubmenuState.EXPANDED);
  redirectedFromNotifications = false;

  constructor(
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController
  ) {}

  loading(message?: string) {
    return this.loadingCtrl
    .create({
      message: message || '',
      cssClass: '-casa-loading',
    });
  }

  closeLoading() {
    this.loadingCtrl.getTop().then(loadingEl => {
      loadingEl?.dismiss();
    });
  }

  errorToast(message: string, position: "top" | "bottom" | "middle" = "top") {
    return this.toastCtrl.create({
      color: 'danger',
      message: message,
      duration: 2500,
      position
    });
  }

  successToast(message: string, position: "top" | "bottom" | "middle" = "top") {
    return this.toastCtrl.create({
      color: 'success',
      message: message,
      duration: 2500,
      position
    });
  }

  successToastv2(message: string, messageService?: MessageService, life: number = 3000) {
    const isMobile = this.isMobile$.getValue();
    if (!messageService && isMobile) {
      this.successToast(message, "bottom").then(toastEl => {
        toastEl.present();
      });
    } else {
      const ms = (messageService) ? messageService : this.messageServiceRef;
      ms?.add({severity: 'success', summary: 'Success', detail: message, life: life});
    }
  }

  warningToastv2(message: string, messageService?: MessageService, life: number = 3000) {
    const ms = (messageService) ? messageService : this.messageServiceRef;
    ms?.add({severity: 'warn', summary: 'Notice', detail: message, life: life});
  }

  errorToastv2(message: string, messageService?: MessageService, life: number = 3000) {
    const isMobile = this.isMobile$.getValue();
    if (!messageService && isMobile) {
      this.errorToast(message, "bottom").then(toastEl => {
        toastEl.present();
      });
    } else {
      const ms = (messageService) ? messageService : this.messageServiceRef;
      ms?.add({severity: 'error', summary: 'Error', detail: message, life: life});
    }
  }

  handleRequestError(errorRes: HttpErrorResponse) {
    console.log(errorRes);
    let errorMessage = 'Unknown error, please try again';
    if (!errorRes.error && !errorRes.status) {
      return throwError(() => new Error(errorMessage));
    }
    if (errorRes.error.message) {
      return throwError(() => new Error(errorRes.error.message));
    }
    if (errorRes.status) {
      switch (errorRes.status) {
        case 404:
          errorMessage = 'Not found';
          break;
        case 401:
          errorMessage = 'Session expired';
          break;
        case 403:
          errorMessage = 'Access denied';
          break;
        case 500:
          errorMessage = errorRes.error.msg;
          break;
      }
    }
    return throwError(() => new Error(errorMessage));
  }

  get noCacheHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Cache-Control', `no-cache, no-store, must-revalidate, post-check=0, pre-check=0`)
      .set('Pragma', `no-cache`)
      .set('Expires', '0');
  }

  silentError(messageError: Error | string) {
    const error = (typeof(messageError) === "string") ? messageError : messageError.message;
    if (error.toLowerCase().includes("outdated")) {
      this.errorToastv2(error, undefined, 5000);
      return "outdated";
    }
    return "";
  }

  /* istanbul ignore next */
  sortByProperty(arrOfObs: any[], key: string) {
    arrOfObs.sort((a,b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
    return arrOfObs;
  }

  /* istanbul ignore next */
  sortByPropertyDesc(arrOfObs: any[], key: string) {
    arrOfObs.sort((a,b) => {
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    });
    return arrOfObs;
  }

  shuffle(array: any[]): any[] {
    return array
      .map(value => ({value, sort: Math.random()}))
      .sort((a,b) => a.sort - b.sort)
      .map(({value}) => value)
  }

  addObjectToArray(array: any[], key: string, obj: any) {
    const indx = array.findIndex(o => o[key] === obj[key]);
    if (indx === -1) {
      array.push(obj);
    }
    return array;
  }

  removeObjectFromArray(array: any[], key: string, obj: any) {
    const indx = array.findIndex(o => o[key] === obj[key]);
    if (indx !== -1) {
      array.splice(indx, 1);
    }
    return array;
  }

  addItemToArray(array: any[], item: any) {
    if (!array.includes(item)) {
      array.push(item);
    }
    return array;
  }

  getEnumKey(value:any, enumm: any) {
    if (value || value === 0) {
      const indexOfS = Object.values(enumm).indexOf(value as any);
      const key = Object.keys(enumm)[indexOfS];
      return key;
    }
    return null;
  }

  isNumeric(str: string, decimal = true) {
    if (decimal) {
      return /^[+-]?\d+(\.\d+)?$/.test(str);
    }
    return /^\d+$/.test(str);
  }

  openLink(url: string, target = "_system", location = 'location=yes') {
    window.open(url, target, location);
  }

  getUrlFilename(url: string) {
    const paths = url.split("/");
    return paths[paths.length - 1];
  }

  getShortName(user?: User): string{
    if (user) {
      return `${user.firstName.split(' ')[0]} ${user.lastName.split(' ')[0]}`;
    }
    return "";
  }

  handleRedirection(redirection: RedirectionResponse, allowBack?: boolean) {
    if (redirection.url) {
      if (redirection.back && allowBack) {
        this.navCtrl.navigateBack(redirection.url);
      } else {
        this.navCtrl.navigateForward(redirection.url);
      }
    }
  }

  redirectTo(url: string | string[], back?: boolean) {
    if (back) {
      this.navCtrl.navigateBack(url);
    } else {
      this.navCtrl.navigateForward(url);
    }
  }
}
