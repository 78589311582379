import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, exhaustMap, take } from 'rxjs';
import { AuthService } from './auth.service';
import { SharedService } from '../shared/shared.service';
import { AuthTokenType } from './auth.interfaces';
import { LAST_VERSION, LAST_VERSION_MOBILE } from '../shared/shared.interfaces';

@Injectable({providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {

  nonAuthedPages = ["casago-casa-app.s3.us-west-2.amazonaws.com"];

  constructor(
    private authService: AuthService,
    private sharedService: SharedService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return this.authService.user$.pipe(
      take(1),
      exhaustMap( user => {
          if( !user ){
              return next.handle(req);
          }
          const userToken = user.token;
          const userId = user.id;
          const tokenType = this.sharedService.isMobile$.getValue() ? AuthTokenType.MOBILE : AuthTokenType.DESKTOP;

          for (const page of this.nonAuthedPages) {
            if (req.url.indexOf(page) !== -1) {
              return next.handle(req);
            }
          }

          const lastVersion = this.sharedService.isMobile$.getValue() ? LAST_VERSION_MOBILE : LAST_VERSION;
          const modifiedReq = req.clone({
              headers: new HttpHeaders().set('Authorization', `Bearer ${userId}@${tokenType}@${userToken}@${lastVersion}`)
          });
          return next.handle(modifiedReq);
      })
    );
  }

}
