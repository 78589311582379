import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanMatch, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { LoggedUser } from '../auth.interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanMatch, CanActivate {

  constructor(private authService: AuthService, private router: Router, private activeRoute: ActivatedRoute){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const redirectTo = `/?redirectTo=${encodeURIComponent(state.url)}`;
    return this.getLoggedUser(redirectTo);
  }

  canMatch(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getLoggedUser();
  }

  async getLoggedUser(redirectTo: string = "/") {
    let user: LoggedUser | null = null;
    await this.authService.recoverSession().then((loggedUser) => {
      user = loggedUser;
    });
    if (user) {
      return true;
    }
    return this.router.parseUrl(redirectTo);
  }
}
